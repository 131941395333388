import {ref, reactive, toRefs, onMounted, defineComponent, getCurrentInstance, computed} from 'vue';

export default defineComponent({
    name: "OperateLogList",
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj=reactive({
            pageListRef:null,
            pageList: {
                isShowFixCol:false,
                queryParam: {},
                modelMethod: utils.Api.buildUrl("/operateLog/pageData")
            }
        })
        return{
            ...toRefs(dataObj)
        }
    }
});